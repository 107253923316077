import { Component, Inject, AfterViewInit, ElementRef, ViewChildren, QueryList, Renderer2 } from '@angular/core';
import { Gains } from './models/gains';
import { GainsService } from './service/gains.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Compteur } from './models/compteur';

@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.scss']
})
export class CalendrierComponent implements AfterViewInit {
  private todaysDate: number;
  private todaysMonth: number;
  public gains: Gains[];
  private readonly STORAGE_KEY_ID = 'id';
  private readonly STORAGE_KEY_PSEUDO = 'pseudo';
  public compteur: number;
  constructor(
    @Inject(SESSION_STORAGE) private storage: StorageService,
    private gainsService: GainsService,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.getGains();
    this.getCompteur();
  }

  @ViewChildren('days') days: QueryList<ElementRef>;
  ngAfterViewInit(): void { }

  public clickDoor(event: any) {
    this.todaysDate = new Date().getDate();
    this.todaysMonth = new Date().getMonth();

    if (!isNaN(event.target.innerHTML)) {
      let noSelected: number = parseInt(event.target.innerHTML);

      if (this.todaysMonth === 11 && this.todaysDate === noSelected) {
        this.trigger(noSelected, event);
      }
    }
  }

  private getGains() {
    this.gainsService
      .getGains(JSON.parse(this.storage.get(this.STORAGE_KEY_PSEUDO)), JSON.parse(this.storage.get(this.STORAGE_KEY_ID)))
      .subscribe((result: Gains[]) => {
        this.gains = result;

        this.gains.forEach(gain => {
          const door: ElementRef = this.days.find(x => x.nativeElement.className === `day${gain.jour}`);
          const targetElement = door.nativeElement.children[0].children[1];

          if (targetElement && targetElement.firstChild && targetElement.firstChild.nodeType === 3) {
            return;
          }

          switch (gain.gain) {
            case 0:
              this.renderer.addClass(targetElement, 'rien');
              break;
            case 1:
              // this.renderer.addClass(targetElement, 'roulette');
              break;
            case 3:
              this.renderer.addClass(targetElement, 'vip');
              break;
            case 4:
              this.renderer.addClass(targetElement, 'holy'); // pot
              break;
            case 5:
              this.renderer.addClass(targetElement, 'holystarter'); // starter set deluxe
              break;
            case 6:
              this.renderer.addClass(targetElement, 'pack'); // pack découverte
              break;
            case 7:
              this.renderer.appendChild(targetElement, this.renderer.createText("CADEAU MYSTERE"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 8:
              this.renderer.appendChild(targetElement, this.renderer.createText("SUB T1"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 9:
              this.renderer.appendChild(targetElement, this.renderer.createText("SUB T2"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 10:
              this.renderer.appendChild(targetElement, this.renderer.createText("SUB T3"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 11:
              this.renderer.appendChild(targetElement, this.renderer.createText("+150 points"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 12:
              this.renderer.appendChild(targetElement, this.renderer.createText("+250 points"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 13:
              this.renderer.appendChild(targetElement, this.renderer.createText("+500 points"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 14:
              this.renderer.appendChild(targetElement, this.renderer.createText("+1000 points"));
              this.renderer.addClass(targetElement, 'texte-gain');
              break;
            case 15:
              this.renderer.addClass(targetElement, 'grade');
              break;
            default:
              break;
          }

          if (door.nativeElement.children[0].classList[1] !== 'open') {
            door.nativeElement.children[0].classList.toggle('open');
          }
        });
      });
  }

  private getCompteur() {
    this.gainsService.getCompteur().subscribe((compteur: Compteur) => {
      this.compteur = compteur.result;
    });
  }

  private trigger(noSelected: number, event: any) {
    this.gainsService.trigger(JSON.parse(this.storage.get(this.STORAGE_KEY_PSEUDO)), JSON.parse(this.storage.get(this.STORAGE_KEY_ID)), noSelected).subscribe(() => {
      this.getGains();
    });
  }

  public arrayOfDay(n: number): any[] {
    return Array(n);
  }
}
